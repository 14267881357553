import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  MinusCircleOutlined,
} from '@ant-design/icons';
import { Badge, Checkbox } from 'antd';
import { TablePaginationConfig } from 'antd/es/table';
import { ColumnProps } from 'antd/lib/table';
import { SorterResult } from 'antd/lib/table/interface';

import { EMPTY_ID } from '@totem/components/common/reference/ReferenceSelector';
import Table from '@totem/components/common/table/Table';
import DeviceActionMenu from '@totem/components/devices/DeviceActionMenu';
import DeviceContext from '@totem/components/devices/devicesContainer/deviceContainerContext';
import { isManaged } from '@totem/components/devices/devicesContainer/utilities';
import TableFilterDropDown from '@totem/components/TableFilterDropDown';
import UserProfileContext from '@totem/components/UserProfileContext';
import { DeviceQueryResult } from '@totem/types/devices';
import { isNotNull, isNull } from '@totem/utilities/common';
import {
  getOperatingSystemFilter,
  getTypeFilter,
} from '@totem/utilities/devices';
import {
  nullifyIfEmpty,
  stringifyArray,
} from '@totem/utilities/tableFilterUtilities';
import { sortStringAscending } from '@totem/utilities/tableUtilities';
import { getFilterOptions } from '@totem/utilities/ticketing';
import { getUserRole } from '@totem/utilities/userUtilities';

import '../devices.css';

type Props = {
  showMultiselect?: boolean;
};

const DevicesContainerTable = ({ showMultiselect }: Props) => {
  const { userProfile } = useContext(UserProfileContext);
  const [vendorFilterVisible, setVendorFilterVisible] =
    useState<boolean>(false);
  const {
    input,
    setInput,
    loading,
    deviceData,
    filterOptions,
    totalRecords,
    selectedDevices,
    selectDevice,
    deselectDevice,
    clearSelectedDevices,
  } = useContext(DeviceContext);
  const isSysAdmin = getUserRole(userProfile) === 3;
  const canMultiSelect =
    getUserRole(userProfile) >= 1 &&
    (isNotNull(showMultiselect) ? showMultiselect : false);

  const getBuildingDisplay = (device: DeviceQueryResult) => {
    if (
      device.building !== null &&
      device.building.id !== null &&
      device.building.id !== EMPTY_ID
    ) {
      return (
        <Link to={`/dashboard/buildings/${device.building.id}`}>
          {device.building.name}
        </Link>
      );
    } else if (device.building !== null) {
      return <span>{device.building.name}</span>;
    }
    return <span>-</span>;
  };

  const getControlSystemDisplay = (device: DeviceQueryResult) => {
    if (
      device.controlSystem !== null &&
      device.controlSystem.id !== null &&
      device.controlSystem.id !== EMPTY_ID
    ) {
      return (
        <Link to={`/dashboard/controlsystems/${device.controlSystem.id}`}>
          {device.controlSystem.name}
        </Link>
      );
    } else if (device.controlSystem !== null) {
      return <span>{device.controlSystem.name}</span>;
    }
    return <span>-</span>;
  };

  const columns: ColumnProps<DeviceQueryResult>[] = [
    {
      title: 'Name',
      dataIndex: 'device.displayName',
      key: 'displayName',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => (
        <Link to={`/dashboard/devices/${device.device.id}`}>
          {device.device.displayName}
        </Link>
      ),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.device.displayName, compB.device.displayName),
    },
    {
      title: 'Type',
      dataIndex: 'device.type',
      key: 'type',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => device.device.type,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.device.type, compB.device.type),
      filterMultiple: true,
      filteredValue: stringifyArray(input.type),
      filters: getTypeFilter(filterOptions),
    },
    {
      title: 'Vendor',
      dataIndex: 'device.vendor',
      key: 'vendor',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => device.device.vendor,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.device.vendor, compB.device.vendor),
      filtered: isNotNull(input.vendor) ? input.vendor.length > 0 : false,
      onFilterDropdownOpenChange: () =>
        setVendorFilterVisible(!vendorFilterVisible),
      filterDropdownOpen: vendorFilterVisible,
      filterDropdown: (
        <TableFilterDropDown
          value={stringifyArray(input.vendor)}
          filterDropdownOpen={vendorFilterVisible}
          onCloseFilter={() => setVendorFilterVisible(false)}
          onChange={(newOptions) => setInput({ vendor: newOptions })}
          options={getFilterOptions(
            isNotNull(filterOptions) ? filterOptions.vendor : null,
          )}
        />
      ),
    },
    {
      title: 'OS',
      dataIndex: 'device.operatingSystem',
      key: 'operatingSystem',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => device.device.operatingSystem,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(
          compA.device.operatingSystem,
          compB.device.operatingSystem,
        ),
      filterMultiple: true,
      filteredValue: stringifyArray(input.operatingSystem),
      filters: getOperatingSystemFilter(filterOptions),
    },
    {
      title: 'IP Addresses',
      dataIndex: 'device.ipAddresses',
      key: 'ipAddresses',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => (
        <span>
          {device.device.ipAddresses !== null &&
            device.device.ipAddresses.map((addr, idx) =>
              idx === 0 ? (
                <span key={addr}>{addr}</span>
              ) : (
                <span key={addr}>
                  <br />
                  {addr}
                </span>
              ),
            )}
        </span>
      ),
    },
    {
      title: 'Region',
      dataIndex: 'region.name',
      key: 'regionId',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => device.region.name,
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.region.name, compB.region.name),
      filterMultiple: true,
      filteredValue: stringifyArray(input.regionId),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.region
          : null,
      ),
    },
    {
      title: 'Building',
      dataIndex: 'building.name',
      key: 'buildingId',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => getBuildingDisplay(device),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.building.name, compB.building.name),
      filterMultiple: true,
      filteredValue: stringifyArray(input.buildingId),
      filters: getFilterOptions(
        typeof filterOptions !== 'undefined' && filterOptions !== null
          ? filterOptions.building
          : null,
      ),
    },
    {
      title: 'Control System',
      dataIndex: 'controlSystem.name',
      key: 'controlSystemId',
      showSorterTooltip: true,
      render: (_, device: DeviceQueryResult) => getControlSystemDisplay(device),
      sortDirections: ['ascend', 'descend'],
      sorter: (compA, compB) =>
        sortStringAscending(compA.controlSystem.name, compB.controlSystem.name),
    },
    {
      title: 'Actions',
      width: 30,
      dataIndex: 'additionalOptions',
      key: 'additionalOptions',
      render: (_, device: DeviceQueryResult) => (
        <div className="center-table-cell">
          <DeviceActionMenu device={device} />
        </div>
      ),
    },
  ];

  const getColumns = () => {
    const cols = [...columns];

    if (isSysAdmin) {
      cols.splice(5, 0, {
        title: 'Managed',
        dataIndex: 'device.flags.managed.status',
        key: 'isManaged',
        showSorterTooltip: true,
        render: (_, device: DeviceQueryResult) =>
          isManaged(device) ? (
            <CheckCircleOutlined
              style={{
                color: 'green',
                fontSize: '20px',
              }}
            />
          ) : (
            <MinusCircleOutlined
              style={{
                fontSize: '20px',
              }}
            />
          ),
        sortDirections: ['ascend', 'descend'],
        sorter: (compA, compB) =>
          sortStringAscending(
            compA.device.flags.managed.status,
            compB.device.flags.managed.status,
          ),
        filterMultiple: false,
        filteredValue: nullifyIfEmpty(input.isManaged),
        filters: [
          {
            text: (
              <span>
                <CheckCircleOutlined
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    marginRight: '15px',
                  }}
                />
                &nbsp;Is Managed
              </span>
            ),
            value: 'ACTIVE',
          },
          {
            text: (
              <span>
                <MinusCircleOutlined
                  style={{
                    fontSize: '20px',
                    marginRight: '15px',
                  }}
                />
                &nbsp;Not Managed
              </span>
            ),
            value: 'INACTIVE',
          },
        ],
      });

      cols.splice(6, 0, {
        title: 'Monitored',
        dataIndex: 'device.flags.monitored.status',
        key: 'isMonitored',
        showSorterTooltip: true,
        render: (_, device: DeviceQueryResult) =>
          device.device.flags.monitored.status === 'ACTIVE' ? (
            <CheckCircleOutlined
              style={{
                color: 'green',
                fontSize: '20px',
              }}
            />
          ) : (
            <MinusCircleOutlined
              style={{
                fontSize: '20px',
              }}
            />
          ),
        sortDirections: ['ascend', 'descend'],
        sorter: (compA, compB) =>
          sortStringAscending(
            compA.device.flags.monitored.status,
            compB.device.flags.monitored.status,
          ),
        filterMultiple: false,
        filteredValue: nullifyIfEmpty(input.isMonitored),
        filters: [
          {
            text: (
              <span>
                <CheckCircleOutlined
                  style={{
                    color: 'green',
                    fontSize: '20px',
                    marginRight: '15px',
                  }}
                />
                &nbsp;Is Monitored
              </span>
            ),
            value: 'ACTIVE',
          },
          {
            text: (
              <span>
                <MinusCircleOutlined
                  style={{
                    fontSize: '20px',
                    marginRight: '15px',
                  }}
                />
                &nbsp;Not Monitored
              </span>
            ),
            value: 'INACTIVE',
          },
        ],
      });
    }

    if (canMultiSelect) {
      cols.splice(0, 0, {
        title: (
          <Badge
            title={'Selected Count'}
            count={isNotNull(selectedDevices) ? selectedDevices.length : 0}
            offset={[0, 2]}
          >
            <CloseCircleOutlined
              style={{ marginTop: 8, marginRight: 8 }}
              onClick={() => clearSelectedDevices()}
            />
          </Badge>
        ),
        render: (_, record: DeviceQueryResult) => (
          <Checkbox
            checked={
              isNull(selectedDevices)
                ? false
                : selectedDevices.findIndex(
                    (chk) => chk.device.id === record.device.id,
                  ) >= 0
            }
            onChange={(evt) => {
              if (evt.target.checked) {
                selectDevice(record);
              } else {
                deselectDevice(record);
              }
            }}
          />
        ),
      });
    }

    return cols;
  };

  const getDevices = () => {
    if (deviceData !== null) {
      if (deviceData.devices !== null) {
        return deviceData.devices;
      }
    }
    return [];
  };

  const handleTableChange = (
    updatedPagination: TablePaginationConfig,
    filters: SorterResult<DeviceQueryResult>,
    sorter,
  ) => {
    const { ...params } = filters;

    let sortDir: string = sorter.order === 'descend' ? '-1' : '1';
    if (typeof sorter.order === 'undefined' || sorter.order === null) {
      if (
        typeof input.sortDirection !== 'undefined' &&
        input.sortDirection !== null
      ) {
        sortDir = input.sortDirection;
      }
    }

    let sortField = input.sortField ? input.sortField : 'lastOccurrence';
    if (
      typeof sorter.field !== 'undefined' &&
      typeof sorter.order !== 'undefined'
    ) {
      sortField = sorter.field;
    }

    // @ts-ignore
    setInput({
      ...input,
      ...params,
      pageSize: updatedPagination.pageSize,
      page: updatedPagination.current,
      sortField,
      sortDirection: sortDir,
    });
  };

  return (
    <Table
      showSorterTooltip
      columns={getColumns()}
      dataSource={getDevices()}
      loading={loading}
      onChange={handleTableChange}
      pagination={{
        current: input.page,
        pageSize: input.pageSize,
        total: totalRecords,
        showSizeChanger: true,
      }}
      rowKey={(record) => record.device.id}
    />
  );
};

export default DevicesContainerTable;
